// Generated by Framer (b35efa8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Text, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["JdxYAOpgl"];

const variantClassNames = {JdxYAOpgl: "framer-v-1h3n33m"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "JdxYAOpgl", title: irqvld7Gw = "Knowledge", image: QhD6zvfcJ, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "JdxYAOpgl", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-sDB5q", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-1h3n33m", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"JdxYAOpgl"} ref={ref} style={{backgroundColor: "rgb(244, 244, 244)", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%", ...style}} transition={transition}><Image background={{alt: "", fit: "fill", ...toResponsiveImage(QhD6zvfcJ)}} className={"framer-i5kp8t"} data-framer-name={"02 Apple"} layoutDependency={layoutDependency} layoutId={"qA3Y1EDRL"} transition={transition}/><Text __fromCanvasComponent alignment={"center"} className={"framer-qxn9m2"} data-framer-name={"Knowledge"} fonts={["GF;IBM Plex Sans Arabic-600"]} layoutDependency={layoutDependency} layoutId={"pe3K_h_Nl"} rawHTML={"<span style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><span style='font-size: 0'><span style=''>Knowledge</span><br></span></span>"} style={{"--framer-font-family": "\"IBM Plex Sans Arabic\", sans-serif", "--framer-font-size": "27.84px", "--framer-font-style": "normal", "--framer-font-weight": 600, "--framer-letter-spacing": "0px", "--framer-line-height": "31.32px", "--framer-text-alignment": "center", "--framer-text-color": "var(--token-512a643a-6a48-47c6-8641-27985ce400fb, rgb(31, 22, 70))", "--framer-text-decoration": "none", "--framer-text-transform": "none"}} text={irqvld7Gw} transition={transition} verticalAlignment={"center"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-sDB5q [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-sDB5q * { box-sizing: border-box; }", ".framer-sDB5q .framer-18cn3oy { display: block; }", ".framer-sDB5q .framer-1h3n33m { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 28px; height: 295px; justify-content: flex-start; overflow: visible; padding: 40px 40px 40px 40px; position: relative; width: 295px; }", ".framer-sDB5q .framer-i5kp8t { aspect-ratio: 1.2232415902140672 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 144px); overflow: hidden; position: relative; width: 82%; }", ".framer-sDB5q .framer-qxn9m2 { flex: none; height: auto; overflow: hidden; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-sDB5q .framer-1h3n33m { gap: 0px; } .framer-sDB5q .framer-1h3n33m > * { margin: 0px; margin-bottom: calc(28px / 2); margin-top: calc(28px / 2); } .framer-sDB5q .framer-1h3n33m > :first-child { margin-top: 0px; } .framer-sDB5q .framer-1h3n33m > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 295
 * @framerIntrinsicWidth 295
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"irqvld7Gw":"title","QhD6zvfcJ":"image"}
 */
const FramerG6JjoLJko: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerG6JjoLJko;

FramerG6JjoLJko.displayName = "Ar / Value round";

FramerG6JjoLJko.defaultProps = {height: 295, width: 295};

addPropertyControls(FramerG6JjoLJko, {irqvld7Gw: {defaultValue: "Knowledge", displayTextArea: false, title: "Title", type: ControlType.String}, QhD6zvfcJ: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerG6JjoLJko, [{family: "IBM Plex Sans Arabic", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/G6JjoLJko:default", url: "https://fonts.gstatic.com/s/ibmplexsansarabic/v9/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YPi-NCTVsVJKxTs.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/ibmplexsansarabic/v9/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YPi-NCTVsVJKxTs.ttf", weight: "600"}])